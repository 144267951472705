import { GetIndexPageData } from '../graphql-queries';
import { initApollo, addToApolloState } from '../utils/apollo-client';
import { IndexPage } from '../components/pages/index-page/index-page';

export default IndexPage;

export async function getStaticProps() {
  const apolloClient = initApollo();
  const { data } = await apolloClient.query({
    query: GetIndexPageData,
  });

  return addToApolloState(apolloClient, {
    props: { data },
  });
}
