import { memo } from 'react';
import { CardMemo, CardProps } from '../card/card';
import { CardScrollerMemo } from '../card-scroller/card-scroller';
import { CardListHeaderMemo } from '../card-list-header/card-list-header';
import {
  FOCUS_SCROLL_CONTAINER_ATTRIBUTE,
  FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE,
} from '../../../utils/nav-system';
import styles from './card-list.module.css';

export type CardListItemProps = Pick<
  CardProps,
  'type' | 'id' | 'name' | 'launchUrl' | 'imageUrl' | 'logoImageUrl' | 'videoUrl' | 'category'
>;

export type CardListProps = {
  id: string;
  items: CardListItemProps[];
  headerTitle: string;
  headerLink?: string;
  headerIcon?: React.ReactNode;
  headerSize?: 'medium' | 'large';
  cardSize?: 'medium' | 'large';
  cardOrientation?: 'landscape' | 'portrait';
  showRanking?: boolean;
};

export function CardList({
  id,
  items,
  headerTitle,
  headerLink,
  headerIcon,
  headerSize,
  cardSize,
  cardOrientation,
  showRanking = false,
}: CardListProps) {
  const rootId = `card-list-${id}`;
  const headerId = `card-list-header-${id}`;
  const scrollerId = `card-list-scroller-${id}`;

  // No items, no render.
  if (items.length === 0) return null;

  return (
    <div
      id={rootId}
      className={styles.root}
      role="region"
      aria-labelledby={headerId}
      {...{
        [FOCUS_SCROLL_CONTAINER_ATTRIBUTE]: 'y',
      }}
    >
      <div
        className={styles.scrollToTarget}
        {...{
          [FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE]: 'y',
        }}
      ></div>
      <CardListHeaderMemo
        id={headerId}
        className={styles.header}
        title={headerTitle}
        link={headerLink}
        icon={headerIcon}
        size={headerSize}
      />
      <CardScrollerMemo className={styles.cards} scrollerId={scrollerId}>
        {items.map((item, index) => (
          <CardMemo
            key={index}
            type={item.type}
            id={item.id}
            name={item.name}
            launchUrl={item.launchUrl}
            imageUrl={item.imageUrl}
            logoImageUrl={item.logoImageUrl}
            videoUrl={item.videoUrl}
            category={item.category}
            size={cardSize}
            orientation={cardOrientation}
            focusScrollContainerAxis="x"
            rank={showRanking ? index + 1 : undefined}
          />
        ))}
      </CardScrollerMemo>
    </div>
  );
}

export const CardListMemo = memo(CardList);
