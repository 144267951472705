export type SlideData = {
  id: string;
  type: string;
  name: string;
  launchUrl: string;
  bgImageUrl: string;
  imageUrl: string;
  shortDescription: string;
  bgVideoUrl: string;
  logoImageUrl: string;
  logoImageWidth: number;
  logoImageHeight: number;
};

export enum SlideVideoState {
  NoSource = 'no-source',
  Loading = 'loading',
  Ready = 'ready',
  Playing = 'playing',
  Paused = 'paused',
  Ended = 'ended',
  Error = 'error',
}
