import type { GetIndexPageDataQuery } from '../../../../.gql/graphql';
import { useMemo } from 'react';

export function useCollections(data: GetIndexPageDataQuery) {
  return useMemo(
    () =>
      data.collections?.map((collection) => {
        return {
          id: collection.id,
          name: collection.name || '',
          items:
            collection.items?.map((item) => {
              return {
                id: item.id,
                type: item.type || '',
                name: item.name || '',
                shortDescription: item.shortDescription || '',
                launchUrl: item.launchUrl || '',
                imageUrl: item.landscapeThumbnail?.url || '',
                bgLandscapeImageUrl: item.backgroundImage?.url || '',
                bgPortraitImageUrl: item.portraitThumbnail?.url || '',
                category: item.categories?.[0]
                  ? {
                      id: item.categories[0].id,
                      name: item.categories?.[0].name || '',
                    }
                  : undefined,
              };
            }) || [],
        };
      }) || [],
    [data],
  );
}
