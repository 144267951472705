import { memo, useMemo, useRef } from 'react';
import { useIsomorphicLayoutEffect } from '../../hooks/use-isomorphic-layout-effect';
import { AnchorButton } from '../buttons/button/button';
import { FeaturedCategoryCardMemo } from './featured-category-card';
import { ARROW_NAV_CONTAINER_CLASS, ARROW_NAV_TARGET_CLASS } from '../../utils/nav-system';
import styles from './featured-categories.module.css';

export type FeaturedCategoriesProps = {
  categories: {
    id: string;
    name: string;
    landscapeImageUrl: string;
    portraitImageUrl: string;
  }[];
};

export function FeaturedCategories({ categories }: FeaturedCategoriesProps) {
  const ref = useRef<HTMLDivElement>(null);

  // We only support displaying up to 4 items.
  const filteredCategories = useMemo(() => {
    const categoriesCopy = [...categories];
    categoriesCopy.length = Math.min(4, categoriesCopy.length);
    return categoriesCopy;
  }, [categories]);

  // We need to compute the dynamic CSS variables for the cards, for the
  // scale effect. We want to expand the card 16px on the wider axis and the
  // other axis proportionally.
  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined') return;

    if (!filteredCategories.length) return;

    const container = ref.current;
    if (!container) return;

    const normalCard = container.querySelector(
      `.${styles.card}:not(.${styles.isWideCard})`,
    ) as HTMLElement | null;

    const wideCard = container.querySelector(
      `.${styles.card}.${styles.isWideCard}`,
    ) as HTMLElement | null;

    const computeVariables = () => {
      [normalCard, wideCard].forEach((card, index) => {
        if (!card) return;
        const { width, height } = card.getBoundingClientRect();
        const scale = width > height ? (width + 16) / width : (height + 16) / height;
        container.style.setProperty(
          `--featured-categories--${index === 0 ? 'normal' : 'wide'}-card-active-scale`,
          `${scale}`,
        );
      });
    };

    // Recompute variables on root element resize. Note that this is also
    // triggered on initial render so we don't need to call computeVariables
    // manually.
    const resizeObserver = new ResizeObserver(computeVariables);
    resizeObserver.observe(container);

    return () => {
      resizeObserver.disconnect();
      container.style.removeProperty('--featured-categories--normal-card-active-scale');
      container.style.removeProperty('--featured-categories--wide-card-active-scale');
    };
  }, [filteredCategories.length]);

  // No items, no render.
  if (!filteredCategories.length) return null;

  return (
    <div ref={ref} className={`${styles.root} ${ARROW_NAV_CONTAINER_CLASS}`}>
      <h2 className={styles.title}>Popular Genres</h2>
      <div className={styles.cards}>
        {filteredCategories.map((category, index) => (
          <FeaturedCategoryCardMemo
            key={category.id}
            name={category.name}
            landscapeImageUrl={category.landscapeImageUrl}
            portraitImageUrl={category.portraitImageUrl}
            launchUrl={`/category/${category.id}`}
            isWide={index === 1 || index === 2}
          />
        ))}
      </div>
      <p className={styles.description}>
        From fast-paced shooters to engaging adventures—dive into a popular genre or explore all our
        games.
      </p>
      <AnchorButton className={`${styles.link} ${ARROW_NAV_TARGET_CLASS}`} href="/games">
        See all games
      </AnchorButton>
    </div>
  );
}

export const FeaturedCategoriesMemo = memo(FeaturedCategories);
