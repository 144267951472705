import { memo, useMemo } from 'react';
import Image from 'next/image';
import { CardVideoMemo } from '../card-video/card-video';
import { AnchorButton } from '../../buttons/button/button';
import { AnchorTag } from '../../tag/tag';
import { DetailLink } from '../../detail-link/detail-link';
import { ChevronDownIcon } from '../../svg-icons/chevron-down-icon';
import { InternalLink } from '../../internal-link/internal-link';
import { useCard } from '../../../hooks/use-card';
import { useStore } from '../../../providers/store-provider';
import {
  ARROW_NAV_TARGET_CLASS,
  ARROW_NAV_RECT_ATTRIBUTE,
  FOCUS_SCROLL_CONTAINER_ATTRIBUTE,
  FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE,
} from '../../../utils/nav-system';
import styles from './card.module.css';

export type CardProps = {
  // Item data.
  id: string;
  type: string;
  name: string;
  launchUrl: string;
  imageUrl: string;
  logoImageUrl?: string;
  videoUrl?: string;
  category?: { id: string; name: string };
  // Other props.
  size?: 'medium' | 'large';
  orientation?: 'landscape' | 'portrait';
  focusScrollContainerAxis?: 'x' | 'y' | '';
  rank?: number;
  onActivate?: () => void;
  onDeactivate?: () => void;
};

export function Card({
  size = 'medium',
  orientation = 'landscape',
  focusScrollContainerAxis = '',
  rank,
  onActivate,
  onDeactivate,
  ...item
}: CardProps) {
  const { cardId, isActive, onPointerEnter, onPointerLeave, onFocus, onBlur } = useCard(
    onActivate,
    onDeactivate,
  );
  const addRecentlyPlayedId = useStore((state) => state.addRecentlyPlayedId);

  const rankString = useMemo(() => {
    return typeof rank === 'number' ? rank.toString().padStart(2, '0') : undefined;
  }, [rank]);

  return (
    <div
      className={`${styles.root} ${styles[size + 'Size']} ${styles[orientation + 'Orientation']}`}
      onPointerDown={onPointerEnter}
      onPointerUp={onPointerEnter}
      onPointerMove={onPointerEnter}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      data-card-id={cardId}
      {...{
        [FOCUS_SCROLL_CONTAINER_ATTRIBUTE]: focusScrollContainerAxis,
      }}
    >
      <div
        className={styles.interactionArea}
        {...{
          [FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE]: focusScrollContainerAxis,
        }}
      ></div>
      <div className={styles.title}>
        {rankString ? <span className={styles.titleRank}>{rankString}</span> : null}
        <span className={styles.titleText}>{item.name}</span>
      </div>
      <div className={styles.imageContainer}>
        <Image
          className={styles.image}
          src={item.imageUrl}
          alt={item.name}
          draggable={false}
          fill
          quality={85}
          sizes={
            size === 'large'
              ? '(max-width: 619px) 80vw,(max-width: 863px) 42vw,(max-width: 1351px) 30vw,(max-width: 1595px) 23vw,(max-width: 1839px) 18vw,(max-width: 2083px) 15vw, 13.5vw'
              : '(max-width: 619px) 80vw,(max-width: 863px) 42vw,(max-width: 1107px) 29vw,(max-width: 1351px) 22vw,(max-width: 1595px) 18vw,(max-width: 1839px) 15vw,(max-width: 2083px) 13vw, 12vw'
          }
        />
        {!!item.logoImageUrl && (
          <div className={styles.logoContainer}>
            <Image
              className={styles.logo}
              aria-hidden="true"
              src={item.logoImageUrl}
              alt={item.name}
              draggable={false}
              fill
              quality={85}
              sizes={
                size === 'large'
                  ? '(max-width: 619px) 80vw,(max-width: 863px) 42vw,(max-width: 1351px) 30vw,(max-width: 1595px) 23vw,(max-width: 1839px) 18vw,(max-width: 2083px) 15vw, 13.5vw'
                  : '(max-width: 619px) 80vw,(max-width: 863px) 42vw,(max-width: 1107px) 29vw,(max-width: 1351px) 22vw,(max-width: 1595px) 18vw,(max-width: 1839px) 15vw,(max-width: 2083px) 13vw, 12vw'
              }
            />
          </div>
        )}
        <CardVideoMemo src={item.videoUrl} isActive={isActive} />
        <InternalLink
          className={styles.imageLink}
          href={item.launchUrl}
          target="_blank"
          rel="noreferrer"
          onClick={() => item.type === 'game' && addRecentlyPlayedId(item.id)}
          tabIndex={-1}
          draggable={false}
        ></InternalLink>
        {!!item.category && (
          <AnchorTag
            className={styles.category}
            name={item.category.name}
            href={`/category/${item.category.id}`}
            {...{
              [ARROW_NAV_RECT_ATTRIBUTE]: `[data-card-id="${cardId}"]`,
            }}
          />
        )}
        <div className={styles.activeTitle}>{item.name}</div>
      </div>
      <div className={styles.actions}>
        <AnchorButton
          className={`${styles.launchAction} ${ARROW_NAV_TARGET_CLASS}`}
          theme="Primary"
          href={item.launchUrl}
          target="_blank"
          rel="noreferrer"
          onClick={() => item.type === 'game' && addRecentlyPlayedId(item.id)}
          {...{
            [ARROW_NAV_RECT_ATTRIBUTE]: `[data-card-id="${cardId}"]`,
          }}
        >
          {item.type === 'game' ? 'Play' : 'Launch'}
        </AnchorButton>
        <DetailLink
          className={styles.infoAction}
          theme="SecondaryLight"
          itemType={item.type}
          itemId={item.id}
          {...{
            [ARROW_NAV_RECT_ATTRIBUTE]: `[data-card-id="${cardId}"]`,
          }}
        >
          <ChevronDownIcon />
        </DetailLink>
      </div>
    </div>
  );
}

export const CardMemo = memo(Card);
