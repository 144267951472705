import { useEffect, useRef, useState, memo } from 'react';
import { useStore } from '../../providers/store-provider';
import { SlideData, SlideVideoState } from './types';
import Image from 'next/image';
import { AnchorButton } from '../buttons/button/button';
import { DetailLink } from '../detail-link/detail-link';
import { ChevronDownIcon } from '../svg-icons/chevron-down-icon';
import { CardListHeaderMemo } from '../cards/card-list-header/card-list-header';
import { BulletSquareIcon } from '../svg-icons/bullet-square';
import {
  ARROW_NAV_TARGET_CLASS,
  ARROW_NAV_CONTAINER_CLASS,
  ARROW_NAV_CONTAIN_ATTRIBUTE,
  ARROW_NAV_IGNORE_ATTRIBUTE,
} from '../../utils/nav-system';
import styles from './slider-slide.module.css';

export type SliderSlideProps = {
  slide: SlideData;
  isActive: boolean;
  isVideoPaused: boolean;
  isFeatured?: boolean;
};

export function SliderSlide({
  slide,
  isActive,
  isVideoPaused,
  isFeatured = false,
}: SliderSlideProps) {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const debugDisableVideos = useStore(({ debug }) => debug.disableVideos);

  const addRecentlyPlayedId = useStore((state) => state.addRecentlyPlayedId);

  const [videoState, setVideoState] = useState(
    slide.bgImageUrl ? SlideVideoState.Loading : SlideVideoState.NoSource,
  );

  // Set initial video state when video url changes.
  useEffect(() => {
    // If debug state is not yet available, do nothing.
    if (debugDisableVideos === undefined) {
      return;
    }

    // If video url is not available or video is disabled in debug mode, set
    // appropriate video state.
    if (!slide.bgVideoUrl || debugDisableVideos) {
      setVideoState(SlideVideoState.NoSource);
      return;
    }

    // Get video element, which should exist at this point.
    const videoElem = videoRef.current;
    if (!videoElem) throw new Error('Video element not available!');

    const onError = () => {
      console.error(videoElem.error);
      setVideoState(SlideVideoState.Error);
    };

    const onCanPlayThrough = () => {
      setVideoState(SlideVideoState.Ready);
    };

    // If video already has an error, set appropriate video state.
    if (videoElem.error) {
      onError();
      return;
    }

    // If video is already ready to be played set appropriate video state.
    if (videoElem.readyState === 4) {
      onCanPlayThrough();
      return;
    }

    // Update video state when video can be played through.
    videoElem.addEventListener('canplaythrough', onCanPlayThrough, { once: true });

    // Update video state if video errors.
    videoElem.addEventListener('error', onError, { once: true });

    // Set video state to loading if the video is preloaded.
    if (videoElem.preload === 'auto') {
      setVideoState(SlideVideoState.Loading);
    }
    // Otherwise, set video state to no source.
    else {
      setVideoState(SlideVideoState.NoSource);
    }

    return () => {
      videoElem.removeEventListener('canplaythrough', onCanPlayThrough);
      videoElem.removeEventListener('error', onError);
    };
  }, [debugDisableVideos, slide.bgVideoUrl, setVideoState]);

  // Force load videos that are not preloaded.
  useEffect(() => {
    const videoElem = videoRef.current;

    if (
      isActive &&
      videoElem &&
      videoElem.preload === 'none' &&
      videoState === SlideVideoState.NoSource
    ) {
      videoElem.load();
      setVideoState(SlideVideoState.Loading);
    }
  }, [isActive, videoState, setVideoState]);

  // Pause/play video.
  useEffect(() => {
    const videoElem = videoRef.current;
    if (!videoElem) return;

    // When slide is deactivated pause video element and reset video state
    // back to ready (if needed) so that it will begin playing from beginning
    // the next time when it becomes active.
    if (!isActive) {
      if (!videoElem.paused) {
        videoElem.pause();
      }
      if (
        videoState === SlideVideoState.Playing ||
        videoState === SlideVideoState.Paused ||
        videoState === SlideVideoState.Ended
      ) {
        setVideoState(SlideVideoState.Ready);
      }
      return;
    }

    // When a paused/ready video becomes playable, play it.
    if (
      !isVideoPaused &&
      (videoState === SlideVideoState.Paused || videoState === SlideVideoState.Ready)
    ) {
      let didUnmount = false;

      if (videoState === SlideVideoState.Ready) {
        videoElem.currentTime = 0;
      }

      videoElem
        .play()
        .then(() => {
          if (didUnmount) return;
          setVideoState(SlideVideoState.Playing);
        })
        .catch((e) => {
          console.error(e);
          if (didUnmount) return;
          setVideoState(SlideVideoState.Error);
        });

      return () => {
        didUnmount = true;
      };
    }

    // When a playing video becomes unplayable, pause it.
    if (isVideoPaused && videoState === SlideVideoState.Playing) {
      if (!videoElem.paused) {
        videoElem.pause();
      }
      setVideoState(SlideVideoState.Paused);
      return;
    }

    return;
  }, [slide.bgVideoUrl, isActive, isVideoPaused, videoState, setVideoState]);

  return (
    <div
      className={styles.root}
      aria-hidden={isFeatured ? 'false' : 'true'}
      data-active={isActive ? 'true' : 'false'}
    >
      <Image
        className={styles.bgImage}
        src={slide.bgImageUrl}
        alt={slide.name}
        aria-hidden="true"
        draggable={false}
        fill
        quality={85}
        priority={isFeatured}
        sizes="100vw"
      />
      {!!slide.bgVideoUrl && !debugDisableVideos && (
        <video
          ref={videoRef}
          className={styles.video}
          src={slide.bgVideoUrl}
          data-active={videoState === SlideVideoState.Playing ? 'true' : 'false'}
          muted={true}
          aria-hidden="true"
          onEnded={() => setVideoState(SlideVideoState.Ended)}
          preload={isFeatured ? 'auto' : 'none'}
        ></video>
      )}
      <div className={styles.sideFade}></div>
      <div className={styles.bottomFade}></div>
      <div className={styles.container}>
        <div className={styles.topContent}>
          {isFeatured && (
            <CardListHeaderMemo
              className={styles.heading}
              title="Featured"
              icon={<BulletSquareIcon />}
            />
          )}
          {!!slide.logoImageUrl && (
            <Image
              className={styles.logo}
              aria-hidden="true"
              src={slide.logoImageUrl}
              alt={slide.name}
              draggable={false}
              priority={isFeatured}
              quality={85}
              sizes="250px"
              width={slide.logoImageWidth}
              height={slide.logoImageHeight}
            />
          )}
        </div>
        <div className={styles.bottomContent}>
          {!!slide.shortDescription && (
            <div className={styles.description}>{slide.shortDescription}</div>
          )}
          {isFeatured && (
            <div
              className={`${styles.actions} ${ARROW_NAV_CONTAINER_CLASS}`}
              {...{
                [ARROW_NAV_CONTAIN_ATTRIBUTE]: 'x',
                [ARROW_NAV_IGNORE_ATTRIBUTE]: 'y',
              }}
            >
              <AnchorButton
                className={`${styles.launchAction} ${ARROW_NAV_TARGET_CLASS}`}
                theme="Primary"
                href={slide.launchUrl}
                target="_blank"
                rel="noreferrer"
                onClick={() => slide.type === 'game' && addRecentlyPlayedId(slide.id)}
              >
                {slide.type === 'game' ? 'Play' : 'Launch'}
              </AnchorButton>
              <DetailLink
                className={styles.infoAction}
                theme="SecondaryLight"
                itemType="game"
                itemId={slide.id}
              >
                <ChevronDownIcon />
              </DetailLink>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export const SliderSlideMemo = memo(SliderSlide);
