import type { GetIndexPageDataQuery } from '../../../../.gql/graphql';
import { useMemo } from 'react';

export function useTrendingItems(data: GetIndexPageDataQuery) {
  return useMemo(() => {
    return (
      data.featuredItems
        ?.filter((data) => {
          return data.group === 'trending-items' && data.item;
        })
        .map((data) => {
          const item = data.item!;
          return {
            id: item.id,
            type: item.type || '',
            name: item.name || '',
            shortDescription: item.shortDescription || '',
            launchUrl: item.launchUrl || '',
            imageUrl: item.portraitThumbnail?.url || '',
            logoImageUrl: item.logoImage?.url || '',
            videoUrl: item.thumbnailVideo?.url || '',
            category: item.categories?.[0]
              ? {
                  id: item.categories[0].id,
                  name: item.categories[0].name || '',
                }
              : undefined,
          };
        }) || []
    );
  }, [data]);
}
