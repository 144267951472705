import type { GetIndexPageDataQuery } from '../../../../.gql/graphql';
import { useMemo } from 'react';

export function useFeaturedCategories(data: GetIndexPageDataQuery) {
  const featuredCategories = useMemo(
    () =>
      data.featuredCategories
        ?.filter(({ category }) => !!category)
        .map((data) => {
          const category = data.category!;
          return {
            id: category.id,
            name: category.name || '',
            landscapeImageUrl: category.landscapeThumbnail?.url || '',
            portraitImageUrl: category.portraitThumbnail?.url || '',
          };
        }) || [],
    [data],
  );
  return featuredCategories;
}
