import { memo } from 'react';
import Image from 'next/image';
import { InternalLink } from '../internal-link/internal-link';
import { ARROW_NAV_TARGET_CLASS } from '../../utils/nav-system';
import styles from './featured-categories.module.css';

export type FeaturedCategoryCardProps = {
  name: string;
  landscapeImageUrl: string;
  portraitImageUrl: string;
  launchUrl: string;
  isWide?: boolean;
};

export function FeaturedCategoryCard({
  name,
  landscapeImageUrl,
  portraitImageUrl,
  launchUrl,
  isWide = false,
}: FeaturedCategoryCardProps) {
  return (
    <InternalLink
      className={`${styles.card} ${isWide ? styles.isWideCard : ''} ${ARROW_NAV_TARGET_CLASS}`}
      href={launchUrl}
    >
      <span className={styles.cardContent}>
        <span className={styles.cardImageContainer}>
          <Image
            className={styles.cardLandscapeImage}
            src={landscapeImageUrl}
            alt={name}
            draggable={false}
            fill
            quality={85}
            sizes="(max-width: 1024px) 55vw, 40vw"
          />
          <Image
            className={styles.cardPortraitImage}
            src={portraitImageUrl}
            alt={name}
            draggable={false}
            fill
            quality={85}
            sizes="(max-width: 1024px) 35vw, 27vw"
          />
        </span>
        <span className={styles.cardFade}></span>
        <span className={styles.cardTitle}>{name}</span>
        <span className={styles.cardHighlight}></span>
      </span>
    </InternalLink>
  );
}

export const FeaturedCategoryCardMemo = memo(FeaturedCategoryCard);
