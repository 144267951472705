import type { GetIndexPageDataQuery } from '../../../../.gql/graphql';
import { useMemo } from 'react';

export function useFeaturedItems(data: GetIndexPageDataQuery) {
  return useMemo(() => {
    return (
      data.featuredItems
        ?.filter((data) => {
          return data.group === 'featured-items' && data.item;
        })
        .map((data) => {
          const item = data.item!;
          return {
            id: item.id,
            type: item.type || '',
            name: item.name || '',
            shortDescription: item.shortDescription || '',
            launchUrl: item.launchUrl || '',
            imageUrl: item.landscapeThumbnail?.url || '',
            videoUrl: item.thumbnailVideo?.url || '',
            categories:
              item.categories?.map((category) => ({
                id: category.id,
                name: category.name || '',
              })) || [],
          };
        }) || []
    );
  }, [data]);
}
